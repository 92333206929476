import useMediaQuery from "@mui/material/useMediaQuery";
import { forwardRef } from "react";
import { AVAILABILITY } from "../../../components/Search/SearchFetchProductsHelper.types";
import { mobileAndTabletNotHorizontalMediaQuery } from "../../../utils/xxl-screen";
import { FilterGridSwitch } from "../../Filter/FilterBar/FilterGridSwitch";
import { AvailabilityBarStyled } from "../AvailabilitySelector/AvailabilityBar.styled";
import type { MultipleAvailabilitySelectorProps } from "../AvailabilitySelector/MultipleAvailabilitySelector";
import { MultipleAvailabilitySelector } from "../AvailabilitySelector/MultipleAvailabilitySelector";

export type AvailabilityBarProps = {
  numberOfProducts: number;
  onChange: MultipleAvailabilitySelectorProps["onChange"];
  selectedAvailability: MultipleAvailabilitySelectorProps["selectedAvailability"];
  selectedColumnsNumber: number;
  selectedStoreIds: MultipleAvailabilitySelectorProps["selectedStoreIds"];
  storesData: MultipleAvailabilitySelectorProps["storesData"];
};

const AvailabilityBar = forwardRef<HTMLDivElement, AvailabilityBarProps>(
  ({ selectedColumnsNumber, ...props }, ref) => {
    const isMobileOrTablet = useMediaQuery(
      mobileAndTabletNotHorizontalMediaQuery
    );
    const {
      numberOfProducts,
      onChange,
      selectedAvailability,
      selectedStoreIds,
      storesData,
    } = props;

    // An empty array of selected stores has previously meant that all stores are selected.
    // This doesn't work for elevate since the api needs IDs of all selected stores.
    const availability =
      selectedStoreIds.length === 0
        ? [AVAILABILITY.ONLINE]
        : selectedAvailability;

    return (
      <AvailabilityBarStyled ref={ref}>
        <MultipleAvailabilitySelector
          onChange={onChange}
          selectedAvailability={availability}
          selectedStoreIds={selectedStoreIds}
          storesData={storesData}
        />
        {isMobileOrTablet ? (
          <FilterGridSwitch
            numberOfProducts={numberOfProducts}
            selectedColumnsNumber={selectedColumnsNumber}
          />
        ) : null}
      </AvailabilityBarStyled>
    );
  }
);

AvailabilityBar.displayName = "AvailabilityBar";

export { AvailabilityBar };
